import * as actionTypes from '../consts/actionTypes';
import * as Sentry from '@sentry/react';
import { eventTracker } from '../helpers/EventTracker/EventTracker';
import {
    pageViewTracking,
    submitRequestFormTracking,
    searchMaskEventTracking,
    iadvizeTracking,
} from './trackingHelpers';
import {
    frontPage,
    thankYouPage,
    shipDetailPage,
    searchResultPage,
    destinationPage,
    cruisePage,
    landingPage,
    companyPage,
    themeOverviewPage,
    cruiselinePage,
    shipOverviewPage,
    lpDestinationOverview,
    lpCompanyOverview,
    mediaOfferPage,
} from '../consts/pageTypes';
import * as qs from 'qs';
import { emarsysTracker } from '../helpers/EmarsysTracker/EmarsysTracker';

/**
 * GTM tracking middleware
 * injected only for the browser environment
 *
 * We should track as much as possible events here. If tracking in middleware is impossible (because event is generated)
 * inside the component without redux involvement, tracking in components should be used
 *
 * @param store
 */
export const tracking = (store) => (next) => (action) => {
    try {
        const { payload, type } = action;
        const storeState = store.getState();
        const { isServerSideRender, pageType: statePageType } = storeState.router;
        switch (type) {
            case actionTypes.updateRatesForSelection:
                iadvizeTracking(store, payload.pageType);
                break;
            case actionTypes.changeRouteSuccess:
                if (!isServerSideRender) {
                    // document.title needs to be set before virtual page view GA event
                    document.title = payload.pageTitle;
                    const url =
                        payload.path !== '/'
                            ? `${payload.baseUrl}${payload.path}`
                            : `${payload.baseUrl}/`;
                    pageViewTracking(store, payload.pageType, url);
                }
                if (isServerSideRender && statePageType === searchResultPage) {
                    eventTracker.track({
                        category: 'mobile-searchresult',
                        label: '1',
                        action: 'pagination',
                    });
                }

                // EmarsysTracker
                const pageType = payload.pageType;
                const trackablePages = [
                    cruiselinePage,
                    shipOverviewPage,
                    lpDestinationOverview,
                    lpCompanyOverview,
                    themeOverviewPage,
                    landingPage,
                    mediaOfferPage,
                ];

                emarsysTracker.trackGenericPageView(
                    payload.seo.titleOg,
                    storeState.pageData.deviceType,
                );

                switch (pageType) {
                    case cruisePage:
                        const cruiseNid = storeState.cruisePage.nid;
                        emarsysTracker.trackView(cruiseNid);
                        break;
                    case searchResultPage:
                        emarsysTracker.trackSearchPage(storeState);
                        emarsysTracker.trackCustomSearchView(storeState);
                        break;
                    case destinationPage:
                        const { destinationDetailPage } = storeState;
                        if (destinationDetailPage?.destinationName) {
                            const destinationName: string = emarsysTracker.formatSearchFilter(
                                destinationDetailPage.destinationName,
                            );
                            emarsysTracker.trackNonSearchPage(payload.path, destinationName);
                        }
                        break;
                    case companyPage:
                        const { companyDetailPage } = storeState;
                        if (companyDetailPage?.companyName) {
                            const companyName: string = emarsysTracker.formatSearchFilter(
                                companyDetailPage.companyName,
                            );
                            emarsysTracker.trackNonSearchPage(payload.path, companyName);
                        }
                        break;
                    case shipDetailPage:
                        if (storeState.shipData) {
                            const { shipPage } = storeState.shipData;
                            if (shipPage?.shipTitle) {
                                const shipName: string = emarsysTracker.formatSearchFilter(
                                    shipPage.shipTitle,
                                );
                                emarsysTracker.trackNonSearchPage(payload.path, shipName);
                            }
                        }
                        break;
                    default:
                        if (trackablePages.includes(pageType)) {
                            emarsysTracker.trackSearchPage(storeState);
                        }
                }
                break;
            case actionTypes.newSearch:
                switch (statePageType) {
                    case shipDetailPage:
                    case frontPage:
                    case searchResultPage:
                    case destinationPage:
                        searchMaskEventTracking(store);
                        break;
                    case thankYouPage:
                        let trackingData = {
                            category: 'mobile-thankyou',
                            label: 'click',
                            action: payload.length === 0 ? 'new search' : 'more trips',
                        };
                        eventTracker.track(trackingData);
                        break;
                }
                break;
            case actionTypes.submitRequestForm:
                submitRequestFormTracking(store, action);
                break;
            case actionTypes.setSortOrder:
                let queryObject = qs.parse(window.document.location.href);
                queryObject.sortString = payload;
                const url = qs.stringify(queryObject, { encode: false });
                eventTracker.track({ category: 'sort filter', action: payload, label: url });
                break;
            case actionTypes.openSearchFilter:
                const { isSearchFilterOpen } = store.getState().search;
                if (!isSearchFilterOpen) {
                    eventTracker.track({
                        category: 'mobile-searchresult',
                        action: 'filter',
                        label: 'click',
                    });
                }
                break;
            case actionTypes.loadMore:
                const pagination = Math.ceil(
                    store.getState().search.searchResults.cruises.length / 10,
                );
                eventTracker.track({
                    category: 'mobile-searchresult',
                    label: pagination.toString(),
                    action: 'pagination',
                });
                break;
            case actionTypes.newsletterSignUpFulfilled:
                eventTracker.track({
                    category: 'mobile-newsletter',
                    label: statePageType,
                    action: 'signup',
                });
                break;
            case actionTypes.handleInquiryResponse:
                if (!payload.isSubmitError) {
                    const cruiseNid = store.getState().requestForm.cruiseNid;
                    if (cruiseNid) {
                        emarsysTracker.trackTags('content_pageview', {
                            booking_request: cruiseNid,
                        });
                    }
                }
                break;
            case actionTypes.addToFavorites:
                if (payload && payload.length) {
                    emarsysTracker.trackTags('content_pageview', {
                        set_favorite: payload[payload.length - 1],
                    });
                }
                break;
        }
        return next(action);
    } catch (err) {
        const storeState = store.getState();
        if (storeState.pageData.appConfig.environment !== 'production') {
            console.log(`Tracking error: ${err}`);
        }
        if (storeState.router.isBrowser) {
            Sentry.captureException(err, {
                extra: { customErr: `tracking exception: ${JSON.stringify(action)}` },
            });
        }
        return next(action);
    }
};
