import { applyTestVariation } from '../consts/actionTypes';
import { isClientSide } from '../helpers/IsomorphicHelpers';
import { readCookie } from '../helpers/cookie';
import * as Sentry from '@sentry/react';
import { getTrackingAbTestsValues } from '../middleware/trackingHelpers';

export enum ActiveTestNames {}

export type ActiveTest = {
    name: ActiveTestNames;
    testId: number;
    variationGroup: number;
} & {
    [key: string]: unknown;
};

// SAMPLE TEST STRUCTURE
// {
//     name: ActiveTestNames.testName,
//     testId: 100000000,
//     originalVariationId: 1001,
//     newVariationId: 1002,
// },
export const activeTests = [];

const getAbTestsFromCookie = (): Record<string, { v: string; g: string }> => {
    const convertTestValue = readCookie('_conv_v') || '{}';
    const experiments = convertTestValue.split('*')?.[5]?.replace('exp:', '');

    if (!experiments) {
        return {};
    }

    const normilizedValue = experiments
        .replace(/-/g, ',')
        .replace(/\./g, ':')
        .replace(/([a-z0-9]+):/g, '"$1":');

    try {
        return JSON.parse(normilizedValue);
    } catch (error) {
        // Sentry.captureException(new Error(`Could not parse AB test data from cookie: ${error}`));
        return {};
    }
};

const stringifyABtests = (tests: ActiveTest[]) =>
    tests.reduce((acc, test) => {
        const abTestVariation = getTrackingAbTestsValues(test);

        return acc.concat(acc ? ', ' : '', abTestVariation);
    }, '');

const exposeTestsToInfinity = (activeTestData) => {
    const stringifiedTests = stringifyABtests(activeTestData);

    if (isClientSide()) {
        window['infinityTests'] = stringifiedTests;
    }
};

const exposeTestsToDataLayer = (activeTestData) => {
    const stringifiedTests = stringifyABtests(activeTestData);

    if (isClientSide()) {
        (window as any).dataLayer.push({
            event: 'ab-test-available',
            abTests: stringifiedTests,
        });
    }
};

export const initABTests: any = () => (dispatch) => {
    let allExperiments = {};

    if (isClientSide()) {
        allExperiments = window?.convert?.currentData?.experiments;
    } else {
        const ssrExperiments = getAbTestsFromCookie();
        allExperiments = Object.entries(ssrExperiments).reduce(
            (acc, [key, value]) => ({ ...acc, [key]: { variation_id: value?.v } }),
            {},
        );
    }

    const activeTestData = activeTests.map((testData) => {
        const cookieValue = JSON.parse(readCookie('override_test') || '{}');
        const originalVariationGroup = allExperiments?.[testData.testId]?.variation_id;
        const overridenVariationGroup = cookieValue?.[testData.testId];

        const variationGroup =
            overridenVariationGroup !== undefined
                ? overridenVariationGroup
                : originalVariationGroup;

        return {
            ...testData,
            variationGroup: Number.parseInt(variationGroup, 10),
        };
    });

    exposeTestsToInfinity(activeTestData);
    exposeTestsToDataLayer(activeTestData);
    dispatch(setAbTestsVariation(activeTestData));
};

const setAbTestsVariation = (activeTestData: ActiveTest[]) => ({
    type: applyTestVariation,
    payload: activeTestData,
});
