import * as React from 'react';
import { __ } from '../../helpers/TranslationService';
import styled from '@emotion/styled';
import { DialogHeader, DialogHeaderTitle, DialogContent } from '../Dialog/Dialog';
import {
    CheckboxLabel,
    CheckboxStyled,
    CheckboxWrapper,
    FilterBox,
    FilterBoxHeading,
    InfoDialogStyled,
    InfoIconStyled,
    InfoIconWrapper,
} from './styles';

const DialogDescription = styled.div`
    font-size: 14px;
    line-height: 22px;
    > p {
        margin-top: 0;
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0;
        }
    }
`;

interface IProps {
    cabinKind: number[];
    onCabinTypeChange(param: number[]): void;
}

export const CabinTypeIdsMapper = {
    inside: 19,
    outside: 20,
    balcony: 21,
    suite: 22,
    shipinship: 23,
};

export const CabinFilter: React.FC<IProps> = (props) => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const handleCabinChange = (typeId) => {
        if (props.cabinKind.includes(typeId)) {
            const filteredCabinIds = props.cabinKind.filter((item) => item !== typeId);
            props.onCabinTypeChange(filteredCabinIds);
        } else {
            const newCabinIds = [...props.cabinKind, typeId];
            props.onCabinTypeChange(newCabinIds);
        }
    };

    const isCabinFilterChecked = (typeId) => {
        return props.cabinKind.includes(typeId);
    };

    const cabinTypes = Object.keys(CabinTypeIdsMapper);

    return (
        <>
            <FilterBox>
                <FilterBoxHeading>{__('cabin', 'dreamlines')}</FilterBoxHeading>
                {cabinTypes.map((cabinType) => {
                    return (
                        <CheckboxWrapper key={cabinType}>
                            <CheckboxStyled
                                id={cabinType}
                                name={cabinType}
                                type="checkbox"
                                onChange={() => handleCabinChange(CabinTypeIdsMapper[cabinType])}
                                checked={isCabinFilterChecked(CabinTypeIdsMapper[cabinType])}
                            />
                            <CheckboxLabel htmlFor={cabinType}>
                                {__(cabinType, 'dreamlines')}
                            </CheckboxLabel>
                            {cabinType === 'shipinship' && (
                                <InfoIconWrapper onClick={() => setIsModalOpen(true)}>
                                    <InfoIconStyled width={16} height={16} />
                                </InfoIconWrapper>
                            )}
                        </CheckboxWrapper>
                    );
                })}
            </FilterBox>
            {isModalOpen && (
                <InfoDialogStyled open onClose={() => setIsModalOpen(false)}>
                    <DialogHeader>
                        <DialogHeaderTitle>{__('shipinship', 'dreamlines')}</DialogHeaderTitle>
                    </DialogHeader>
                    <DialogContent>
                        <DialogDescription
                            dangerouslySetInnerHTML={{
                                __html: __('shipinship.description', 'dreamlines'),
                            }}
                        />
                    </DialogContent>
                </InfoDialogStyled>
            )}
        </>
    );
};

export default CabinFilter;
