export const colors = {
    gunmetal: {
        100: '#1A181E',
        70: 'rgba(46, 52, 56, 0.7)',
        50: 'rgba(46, 52, 56, 0.5)',
    },
    white: {
        100: '#fff',
        50: 'rgba(255, 255, 255, 0.5)',
    },
    whiteSmoke: {
        100: '#F5F5F5',
    },
    soapstone: {
        100: '#FDFDFD',
    },
    desertStorm: {
        100: '#F8F8F8',
    },
    seashell: {
        100: '#F0F0F0',
    },
    aliceBlue: {
        100: '#F5FBFF',
    },
    sky: {
        100: '#EEF8FF',
    },
    cornFlower: {
        100: '#C4E7FF',
    },
    darkCornFlower: {
        100: '#20358C',
        90: 'rgba(32, 53, 140, 0.9)',
    },
    yellowSea: {
        100: '#FFA900',
        50: 'rgba(255, 169, 0, 0.5)',
        20: 'rgba(255, 169, 0, 0.2)',
    },
    mango: {
        100: '#FFC224',
        50: 'rgba(255, 194, 36, 0.5)',
    },
    orangePeel: {
        100: '#F7A101',
    },
    milk: {
        100: '#FFF8EB',
    },
    blanchedAlmond: {
        100: '#FFEECC',
    },
    cosmicLatte: {
        100: '#FFF8EB',
    },
    iron: {
        100: '#E6E7E9',
    },
    nebula: {
        100: '#C7D4DD',
    },
    azure: {
        100: '#0099FF',
        50: 'rgba(0, 153, 255, 0.5)',
        20: '#CCEBFF',
        8: 'rgba(0, 153, 255, 0.08)',
        4: 'rgba(0, 153, 255, 0.04)',
    },
    cotton: {
        100: '#F3F6FF',
    },
    cerulean: {
        100: '#008AE7',
    },
    turtleGreen: {
        100: '#84BA4D',
        20: 'rgba(132, 186, 77, 0.2)',
        4: 'rgba(132, 186, 77, 0.04)',
    },
    indigo: {
        100: '#8F00FF',
        20: 'rgba(143, 0, 255, 0.2)',
        4: 'rgba(143, 0, 255, 0.04)',
    },
    golden: {
        100: '#FFB500',
        20: 'rgba(255, 181, 0, 0.2)',
        4: 'rgba(255, 181, 0, 0.04)',
    },
    watermelon: {
        100: '#EE3D58',
        20: 'rgba(238, 61, 88, 0.20)',
        4: 'rgba(238, 61, 88, 0.04)',
    },
    black: {
        50: 'rgba(0, 0, 0, 0.5)',
        100: '#000',
    },
    imperialBlue: {
        100: '#001477',
        20: 'rgba(0, 20, 119, 0.2)',
        4: 'rgba(0, 20, 119, 0.04)',
    },
    darkCharcoal: {
        100: '#333333',
        70: 'rgba(51, 51, 51, 0.7)',
        50: 'rgba(51, 51, 51, 0.5)',
    },
    cetaceanBlue: {
        100: '#010B3D',
        30: 'rgba(1, 11, 61, 0.3)',
        70: 'rgba(1, 11, 61, 0.7)',
        50: 'rgba(1, 11, 61, 0.5)',
    },
    taupe: {
        100: '#86898B',
    },
    darkCerulean: {
        100: '#164E78',
        70: 'rgba(11, 78, 128, 0.7)',
    },
    peacockBlue: {
        100: '#006295',
        90: 'rgba(0, 98, 149, 0.9)',
        15: 'rgba(22, 78, 120, 0.15)',
        6: 'rgba(22, 78, 120, 0.06)',
    },
    maastricht: {
        100: '#00233b',
    },
    sanctuary: {
        100: '#c3c3c3',
    },
    lavender: {
        100: '#E8ECFF',
    },
    darkRaspberry: {
        100: '#931F63',
    },
    freshAir: {
        100: '#ADDDFF',
    },
    heatWave: {
        100: '#FF7A00',
        20: 'rgba(255, 122, 0, 0.2)',
        4: 'rgba(255, 122, 0, 0.04)',
    },
    panache: {
        100: '#F2FFE5',
    },
    magenta: {
        100: 'rgba(233, 0, 136, 1)',
    },
    raspberry: {
        100: 'rgba(193, 0, 117, 1)',
    },
};
