import { Request, Response } from 'express';
import expressHttpContext from 'express-http-context';
import * as url from 'url';
import * as qs from 'qs';

export class IsomorphicHelpers {
    static isServerSide: boolean;
    static bootstrapTime: number;

    static setServerSide() {
        this.isServerSide = true;
    }
}

interface IHttpContext {
    request: Request;
    response: Response;
}

export const getHttpContext = (): IHttpContext => {
    return {
        request: expressHttpContext.get('req'),
        response: expressHttpContext.get('res'),
    };
};

export const isClientSide = () =>
    !!(typeof window !== 'undefined' && window.document && window.document.createElement);

export const getQueryParams = () => {
    const httpContext = getHttpContext();
    const currentUrl = IsomorphicHelpers.isServerSide ? httpContext.request.url : location.href;
    const queryString = url.parse(currentUrl).query;
    return qs.parse(queryString);
};

export const getReferrer = () => {
    const httpContext = getHttpContext();
    const referral = IsomorphicHelpers.isServerSide
        ? httpContext.request.get('Referrer')
        : document.referrer;
    return referral;
};
