import styled from '@emotion/styled';
import { InfoIconNew } from '../Icons/InfoIconNew';
import { Dialog } from '../Dialog/Dialog';
import { BreakpointsEnum } from '../../helpers/breakpoints';

export const FilterBox = styled.div`
    margin: 15px 0;
    padding: 15px 15px 24px 15px;
    background-color: ${({ theme }) => theme.colors.white[100]};
    border-radius: ${({ theme }) => theme.container.borderRadius};
`;

export const FilterBoxHeading = styled.div`
    font-weight: bold;
    text-align: left;
    margin-bottom: 10px;
    font-size: 20px;
`;

export const CheckboxWrapper = styled.div`
    margin-top: 20;
    display: flex;
    align-items: center;
`;

export const CheckboxStyled = styled.input`
    &[type='checkbox'] {
        display: inline-block;
        vertical-align: top;
        margin-right: 8px;
        margin-top: 0;
        height: 22px;
        width: 22px;
    }
`;

export const CheckboxLabel = styled.label`
    display: inline-block;
    vertical-align: top;
    white-space: normal;
    font-size: 15px;
    margin: 0;
`;

export const InfoIconWrapper = styled.span`
    margin: -2px 0 0 8px;
    cursor: pointer;
`;

export const InfoIconStyled = styled(InfoIconNew)`
    color: ${({ theme }) => theme.icon.brandColor1};
`;

export const InfoDialogStyled = styled(Dialog)`
    @media (min-width: ${BreakpointsEnum.sm}) {
        max-width: 600px;
    }
`;
