import { IsomorphicHelpers, getHttpContext } from './IsomorphicHelpers';

export function readCookie(name: string): string {
    const httpContext = getHttpContext();
    try {
        if (IsomorphicHelpers.isServerSide) {
            return (
                httpContext.response?.get('set-cookie')?.includes(`${name}=true`) ||
                httpContext.request.cookies[name]
            );
        }
    } catch (e) {
        return null;
    }
    const result = new RegExp(`(?:^|; )${encodeURIComponent(name)}=([^;]*)`).exec(document.cookie);
    return result ? result[1] : null;
}

export function writeCookie(name: string, value: string, days: number = 365): void {
    const date = new Date();
    const maxAge = days * 24 * 60 * 60 * 1000;
    date.setTime(date.getTime() + maxAge);
    const httpContext = getHttpContext();

    if (IsomorphicHelpers.isServerSide) {
        const hostname = httpContext.request.hostname;
        const domain = hostname === 'localhost' ? {} : { domain: hostname };
        httpContext.response.cookie(name, value, { maxAge, expires: date, ...domain });
        return;
    }

    const domainParts = location.hostname.split('.');
    domainParts.shift();
    const expires = `; expires=${date.toUTCString()}`;
    const domainString = `;domain=.${domainParts.join('.')}`;
    if (location.hostname === 'localhost') {
        // condition for testing cookie on local only
        document.cookie = `${name}=${value}${expires}; path=/`;
    } else {
        document.cookie = `${name}=${value}${expires}; path=/${domainString}`;
    }
}

export function writeSessionCookie(name: string, value: string): void {
    document.cookie = `${name}=${value}; path=/`;
}

export function removeCookie(name: string): void {
    writeCookie(name, '', -1);
}

/**
 * Set the newsletter modal cookie (right now no need it for unsubscribe)
 */
export function setNewsletterModalCookie(duration: number = 30): void {
    writeCookie('__hiNeP', '1', duration);
}

/**
 * Check whether the newsletter cookie is set or not and show the
 * modal accordingly
 */
export function isNewsletterModalCookie(): boolean {
    return readCookie('__hiNeP') === '1';
}

export function hasNewsletterHiddenCookie(): boolean {
    return readCookie('__hNLP') === 'true';
}

/**
 * Set the newsletter cookie to be true (right now no need for unsubscribe)
 * It will also set the modal cookie (__hiNeP)
 */
export function setNewsletterSubmitCookie(duration: number = 30): void {
    setNewsletterModalCookie();
    writeCookie('DLhasLead', 'true', duration);
}

/**
 * Set the newsletter cookie is set or not
 * and show the modal accordingly
 */
export function isNewsletterSubmitCookie(): boolean {
    return readCookie('DLhasLead') === 'true';
}
