import { IPersonalData } from '../interfaces/IRequestForm';

//root reducer
export const setFeatures = 'SET_FEATURES';
export const setSeo = 'SET_SEO';

//pageData reducer
export const setRequestFormConfig = 'SET_REQUEST_FORM_CONFIG';

// router reducer
export const changeRouteSuccess = 'CHANGE_ROUTE_SUCCESS';
export const initHistory = 'INIT_HISTORY';
export const setEnvironment = 'SET_ENVIRONMENT';
export const saveScrollPosition = 'SAVE_SCROLL_POSITION';

export const loadShipOverviewPageData = 'LOAD_SHIP_OVERVIEW_PAGE_DATA';
export const updateShipOverviewPrices = 'UPDATE_SHIP_OVERVIEW_PRICES';
export const loadShipPageData = 'LOAD_SHIPPAGE_DATA';
export const setLocalization = 'SET_L10N';

// live position page
export const loadShipLivePositionPageData = 'LOAD_SHIP_POSITION_PAGE_DATA';

//cruisePage reducer
export const loadCruisePageData = 'LOAD_CRUISE_PAGE_DATA';
export const loadCruisePageDataPending = 'LOAD_CRUISE_PAGE_DATA_PENDING';
export const loadCruisePageDataRejected = 'LOAD_CRUISE_PAGE_DATA_REJECTED';
export const loadRates = 'LOAD_RATES';
export const loadRatesFulfilled = 'LOAD_RATES_FULFILLED';
export const loadRatesPending = 'LOAD_RATES_PENDING';
export const loadRatesRejected = 'LOAD_RATES_REJECTED';
export const updateDirectBookingConfig = 'UPDATE_DIRECT_BOOKING_CONFIG';
export const updateCruisePageState = 'UPDATE_CRUISE_PAGE_STATE';
export const loadRoute = 'LOAD_ROUTE';
export const loadDecks = 'LOAD_DECKS';

// Loading indicator
export const showLoadingIndicator = 'SHOW_LOADING_INDICATOR';
export const hideLoadingIndicator = 'HIDE_LOADING_INDICATOR';

export const changeSail = 'CHANGE_SAIL';
export const setSelectedSailIndex = 'SET_SELECTED_SAIL_INDEX';

// search reducer
export const newSearch = 'NEW_SEARCH';
export const newFacetsSearch = 'NEW_FACETS_SEARCH';
export const loadMore = 'LOAD_MORE';
export const appendNextResults = 'APPEND_NEXT_RESULTS';
export const toggleSearchLoader = 'TOGGLE_SEARCH_LOADER';
export const toggleFacetsLoader = 'TOGGLE_FACETS_LOADER';
export const setFilterParam = 'SET_FILTER_PARAM';
export const clearFilterParams = 'CLEAR_FILTER_PARAMS';
export const deleteFilterParam = 'DELETE_FILTER_PARAM';
export const setSortOrder = 'SET_SORT_ORDER';
export const updateFacets = 'UPDATE_FACETS';
export const updateFacetsAll = 'UPDATE_FACETS_ALL';
export const updateFacetsSearchBox = 'UPDATE_FACETS_SEARCH_BOX';
export const clearSearchResults = 'CLEAR_SEARCH_RESULTS';
export const loadTopSearchResults = 'LOAD_TOP_SEARCH_RESULTS';
export const loadSearchResults = 'LOAD_SEARCH_RESULTS';
export const cancelSearch = 'CANCEL_SEARCH';
export const toggleSearchOverlay = 'TOGGLE_SEARCH_OVERLAY';

// location things
export const setLocation = 'SET_LOCATION';

export const loadStandardContentPageData = 'LOAD_STANDARD_CONTENT_PAGE_DATA';
export const loadContactPageData = 'LOAD_CONTACT_PAGE_DATA';
export const loadFAQPageData = 'LOAD_FAQ_PAGE_DATA';
export const loadDestinationOverviewPageData = 'LOAD_DESTINATION_OVERVIEW_PAGE_DATA';
export const loadCompanyOverviewPageData = 'LOAD_COMPANY_OVERVIEW_PAGE_DATA';
export const loadInsuranceData = 'LOAD_INSURANCE_DATA';

export const loadHomePageData = 'LOAD_HOME_PAGE_DATA';
export const loadHomePageDataPending = 'LOAD_HOME_PAGE_DATA_PENDING';

export const loadCompanyDetailPageData = 'LOAD_COMPANY_DETAIL_PAGE_DATA';
export const loadDestinationDetailPageData = 'LOAD_DESTINATION_DETAIL_PAGE_DATA';
export const loadLandingPageData = 'LOAD_LANDING_PAGE_DATA';
export const loadFavoritePageData = 'LOAD_FAVORITE_PAGE_DATA';
export const loadThemeOverviewPageData = 'LOAD_THEME_OVERVIEW_PAGE_DATA';
export const loadMediaOfferPageData = 'LOAD_MEDIA_OFFER_PAGE_DATA';
export const updateCabins = 'UPDATE_CABINS';

export const openSearchFilter = 'OPEN_SEARCH_FILTER';

// Seach filters
export const setSearchFilterZone = 'SET_SEARCH_FILTER_ZONE';
export const setSearchFilterCompany = 'SET_SEARCH_FILTER_COMPANY';

// Thank you page actions
export const loadRecommendations = 'LOAD_RECOMMENDATIONS';
export const setThankYouData = 'SET_THANK_YOU_DATA';

//request form
export const setRequestFormInitialData = 'SET_REQUEST_FORM_INITIAL_DATA';
export const setSelectedCabinIndexByNid = 'SET_SELECTED_CABIN_INDEX_BY_NID';
export const setAdultsNum = 'SET_ADULTS_NUM';
export const setChildrenNum = 'SET_CHILDREN_NUM';
export const setAirport = 'SET_AIRPORT';
export const setFlightClass = 'SET_FLIGHT_CLASS';
export const loadPaxConfig = 'LOAD_PAX_CONFIG';
export const loadPaxConfigFulfilled = 'LOAD_PAX_CONFIG_FULFILLED';
export const setChildAge = 'SET_CHILD_AGE';
export const updateRatesForSelection = 'UPDATE_RATES_FOR_SELECTION';
export const resetRequestForm = 'RESET_REQUEST_FORM';
export const submitRequestForm = 'SUBMIT_REQUEST_FORM';
export const setIsEstimatedPrice = 'SET_IS_ESTIMATED_PRICE';
export const handleInquiryResponse = 'HANDLE_INQUIRY_RESPONSE';
export const updatePersonalDataForm = 'UPDATE_PERSONAL_DATA_FORM';
export const updateRequestFormFromCookies = 'UPDATE_REQUEST_FORM_FROM_COOKIES';
export const updateRequestFormFields = 'UPDATE_REQUEST_FORM_FIELDS';

// UGC
export const getUGCReviews = 'GET_UGC_REVIEWS';
export const getUGCReviewsPending = 'GET_UGC_REVIEWS_PENDING';
export const getUGCReviewsFullFilled = 'GET_UGC_REVIEWS_FULFILLED';
export const getUGCReviewsRejected = 'GET_UGC_REVIEWS_REJECTED';

export const getMoreUGCReviews = 'GET_MORE_UGC_REVIEWS';
export const setReviewsSortString = 'SET_REVIEWS_SORT_STRING';
export const toggleUGCreviewsLoading = 'TOGGLE_UGC_REVIEWS_LOADING';

// newsletter
export const newsletterSignUp = 'NEWSLETTER_SIGN_UP';
export const newsletterSignUpFulfilled = 'NEWSLETTER_SIGN_UP_FULFILLED';
export const newsletterSignUpPending = 'NEWSLETTER_SIGN_UP_PENDING';
export const newsletterSignUpRejected = 'NEWSLETTER_SIGN_UP_REJECTED';
export const loadNewsletterPageData = 'LOAD_NEWSLETTER_PAGE_DATA';
export const updateNewsletterPopUpData = 'UPDATE_NEWSLETTER_POPUP_DATA';

//favorites page
export const loadFavoritesCruiseData = 'LOAD_FAVORITES_CRUISE_DATA';
export const loadFavoritesCruiseNids = 'LOAD_FAVORITES_CRUISE_NIDS';
export const addToFavorites = 'ADD_TO_FAVORITES';
export const removeFromFavorites = 'REMOVE_FROM_FAVORITES';
export const updateFavorites = 'UPDATE_FAVORITES';
export const setFavoritesShareLink = 'SET_FAVORITES_SHARE_LINK';

// company terms pages
export const loadCompanyTermsList = 'LOAD_COMPANY_TERMS_LIST';
export const loadCompanyTerms = 'LOAD_COMPANY_TERMS';

// Footer
export const getFooterByPageType = 'GET_FOOTER_BY_PAGE_TYPE';

export const callbackTimeRequired = 'CALLBACK_TIME_REQUIRED';

//Back Link Referrer
export const setIsLoaded = 'SET_IS_LOADED';
export const setIsDesktop = 'SET_IS_DESKTOP';

// Dynamic Form
export const getDynamicFormByIdentifier = 'GET_DYNAMIC_FORM_BY_IDENTIFIER';
export const setDynamicFormInitialData = 'SET_DYNAMIC_FORM_INITIAL_DATA';
export const updateDynamicFormUserData = 'UPDATE_DYNAMIC_FORM_USER_DATA';

// Contact Form
export const setContactFormInitialData = 'SET_CONTACT_FORM_INITIAL_DATA';
export const updateContactFormData = 'UPDATE_CONTACT_FORM_DATA';
export const deleteContactFormData = 'DELETE_CONTACT_FORM_DATA';

// Ship Overview
export const getShipOverview = 'GET_SHIP_OVERVIEW';
export const newSortRequest = 'NEW_SORT_REQUEST';
export const loadMoreRejected = 'LOAD_MORE_REJECTED';
export const newSortRequestRejected = 'NEW_SORT_REQUEST_REJECTED';
export const newSearchRejected = 'NEW_SEARCH_REJECTED';
export const loadMorePending = 'LOAD_MORE_PENDING';
export const newSortRequestPending = 'NEW_SORT_REQUEST_PENDING';
export const newSearchPending = 'NEW_SEARCH_PENDING';
export const loadMoreFulfilled = 'LOAD_MORE_FULFILLED';
export const newSortRequestFulfilled = 'NEW_SORT_REQUEST_FULFILLED';
export const newSearchFulfilled = 'NEW_SEARCH_FULFILLED';

// AB tests
export const applyTestVariation = 'APPLY_AB_TEST_VARIATION';

export enum CruiseActionEnum {
    SET_CABIN = 'SET_CABIN',
}
export interface SetCabinAction {
    type: CruiseActionEnum.SET_CABIN;
    payload: number;
}
export type CruiseAction = SetCabinAction;

export enum PersonalDataActionEnum {
    SET_PERSONALDATA = 'SET_PERSONALDATA',
    SET_AIRPORT_CODE = 'SET_AIRPORT_CODE',
    SET_FLIGHT_CLASS = 'SET_FLIGHT_CLASS',
}

export interface SetPersonalDataAction {
    type: PersonalDataActionEnum.SET_PERSONALDATA;
    payload: Partial<IPersonalData>;
}
export interface SetAirportCodeAction {
    type: PersonalDataActionEnum.SET_AIRPORT_CODE;
    payload: string;
}
export type PersonalDataAction = SetPersonalDataAction | SetAirportCodeAction;
