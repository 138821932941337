import { ICruiseFilterParamType } from '../interfaces/ISearchApi';

export const filterParamsSchema: ICruiseFilterParamType[] = [
    {
        paramName: 'zone',
        paramType: 'number',
        isArray: true,
    },
    {
        paramName: 'priceMin',
        paramType: 'number',
        isArray: false,
    },
    {
        paramName: 'priceMax',
        paramType: 'number',
        isArray: false,
    },
    {
        paramName: 'ship',
        paramType: 'number',
        isArray: true,
    },
    {
        paramName: 'shipCategoryMin',
        paramType: 'number',
        isArray: false,
    },
    {
        paramName: 'shipCategoryMax',
        paramType: 'number',
        isArray: false,
    },
    {
        paramName: 'shipCategory',
        paramType: 'number',
        isArray: true,
    },
    {
        paramName: 'shipSizeMin',
        paramType: 'number',
        isArray: false,
    },
    {
        paramName: 'shipSizeMax',
        paramType: 'number',
        isArray: false,
    },
    {
        paramName: 'shipType',
        paramType: 'number',
        isArray: true,
    },
    {
        paramName: 'cabinKind',
        paramType: 'number',
        isArray: true,
    },
    {
        paramName: 'nightsMin',
        paramType: 'number',
        isArray: false,
    },
    {
        paramName: 'nightsMax',
        paramType: 'number',
        isArray: false,
    },
    {
        paramName: 'startHarbour',
        paramType: 'number',
        isArray: true,
    },
    {
        paramName: 'startHarbourCountry',
        paramType: 'string',
        isArray: true,
    },
    {
        paramName: 'endHarbour',
        paramType: 'number',
        isArray: true,
    },
    {
        paramName: 'endHarbourCountry',
        paramType: 'string',
        isArray: true,
    },
    {
        paramName: 'harbour',
        paramType: 'number',
        isArray: true,
    },
    {
        paramName: 'country',
        paramType: 'string',
        isArray: true,
    },
    {
        paramName: 'dateMin',
        paramType: 'number',
        isArray: false,
    },
    {
        paramName: 'dateMax',
        paramType: 'number',
        isArray: false,
    },
    {
        paramName: 'departureMax',
        paramType: 'number',
        isArray: false,
    },
    {
        paramName: 'country',
        paramType: 'string',
        isArray: true,
    },
    {
        paramName: 'company',
        paramType: 'number',
        isArray: true,
    },
    {
        paramName: 'operator',
        paramType: 'number',
        isArray: true,
    },
    {
        paramName: 'banderoles',
        paramType: 'number',
        isArray: true,
    },
    {
        paramName: 'route',
        paramType: 'number',
        isArray: true,
    },
    {
        paramName: 'inclFlight',
        paramType: 'boolean',
        isArray: false,
    },
    {
        paramName: 'hasTvOffer',
        paramType: 'boolean',
        isArray: false,
    },
    {
        paramName: 'optionalFlight',
        paramType: 'boolean',
        isArray: false,
    },
    {
        paramName: 'inclOrOptionalFlight',
        paramType: 'boolean',
        isArray: false,
    },
    {
        paramName: 'cruiseNids',
        paramType: 'number',
        isArray: true,
    },
    {
        paramName: 'excludeCruiseNids',
        paramType: 'number',
        isArray: true,
    },
    {
        paramName: 'excludedCompanyNids',
        paramType: 'number',
        isArray: true,
    },
    {
        paramName: 'visaFree',
        paramType: 'boolean',
        isArray: false,
    },
    {
        paramName: 'landOfCountrySpoken',
        paramType: 'boolean',
        isArray: false,
    },
    {
        paramName: 'showOnlyAllInclusive',
        paramType: 'boolean',
        isArray: false,
    },
    {
        paramName: 'hotelStay',
        paramType: 'boolean',
        isArray: false,
    },
    {
        paramName: 'cruiseIcon',
        paramType: 'number',
        isArray: true,
    },
    {
        paramName: 'areaType',
        paramType: 'string',
        enumValues: ['river', 'sea'],
        isArray: false,
    },
    {
        paramName: 'hidden',
        paramType: 'boolean',
        isArray: false,
    },
    {
        paramName: 'discount',
        paramType: 'boolean',
        isArray: false,
    },
    {
        paramName: 'cabinKind',
        paramType: 'string',
        isArray: true,
    },
];
